export const servicesComponent = {
    showMore: () => {
        if(!document.querySelector('.services-section')) return;
        const section = document.querySelector('.services-section');
        const button = section.querySelector('.show-more-btn');
        function handler() {
            section.classList.toggle('show')
        }
        button.addEventListener('click', handler);
    }
}