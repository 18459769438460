/**********Sticky header ************ */
document.addEventListener("DOMContentLoaded", onDOMReady);
function onDOMReady() {
    let menuHeader = document.querySelector('header');
    let mainContainer = document.querySelector('main');
    if(Boolean(menuHeader, mainContainer)) {
        window.addEventListener('scroll', onWindowScroll);
        function onWindowScroll() {
            if(window.document.scrollingElement.scrollTop > 300){
                menuHeader.classList.add("sticky");
                mainContainer.classList.add("main__margin-top");
            }
            else {
                menuHeader.classList.remove("sticky")
                mainContainer.classList.remove("main__margin-top");
            }
        }
    }
}
/**********Sticky social links ************ */
const socialListIcons = document.querySelector('.footer-section .social-list-icons');
function socialListIconsSticky() {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.scrollHeight;
    const scrollThreshold = documentHeight - 1000;
    if (documentHeight > windowHeight) {
        if (scrollPosition < scrollThreshold) {
            socialListIcons.classList.add('sticky');
        } else {
            socialListIcons.classList.remove('sticky');
        }
    } else {
        socialListIcons.classList.remove('sticky');
    }
}
document.addEventListener("DOMContentLoaded", socialListIconsSticky);
window.addEventListener('scroll', socialListIconsSticky);

/* **** Burger **** */
const burgerHandler = () => {
    const burgerBtn = document.querySelector('.burger-menu');
    const closeBtn = document.querySelector('#burger-icon_close');
    const menu = document.querySelector('.main-menu');
    const body = document.querySelector('html');
    const contacts = document.querySelector('.open-contacts');
    const openModal = menu.querySelector('.btn-257');
    const overlay = document.querySelector('.overlay');

    const openMenu = () => {
        burgerBtn.classList.add('show');
        menu.classList.add('show', 'menu-open');
        body.classList.add('show');
        document.addEventListener('keydown', handleEscKey);
        burgerBtn.setAttribute('aria-expanded', 'true');
        closeBtn.setAttribute('aria-expanded', 'true');
        overlay.classList.add('show');
    };

    const closeMenu = () => {
        burgerBtn.classList.remove('show');
        menu.classList.remove('show', 'menu-open');
        body.classList.remove('show');
        document.removeEventListener('keydown', handleEscKey);
        burgerBtn.setAttribute('aria-expanded', 'false');
        closeBtn.setAttribute('aria-expanded', 'false');
        overlay.classList.remove('show');
    };

    openModal?.addEventListener('click', closeMenu);

    const isMenuOpen = () => menu.classList.contains('menu-open');

    const handleEscKey = (event) => {
        if (event.key === 'Escape') {
            closeMenu();
            menu.querySelector('h4').innerText = 'Меню';
            menu.classList.remove('show', 'contacts-open');
        }
    };

    document.addEventListener('click', (event) => {
        const isClickInsideMenu = menu.contains(event.target);
        const isClickOnBurger = burgerBtn.contains(event.target) || event.target === burgerBtn;
        const isClickOnCloseBtn = closeBtn.contains(event.target) || event.target === closeBtn;
        const isClickOnContactsBtn = contacts.contains(event.target) || event.target === contacts;

        if (!isClickInsideMenu && !isClickOnBurger && !isClickOnCloseBtn && !isClickOnContactsBtn && isMenuOpen()) {
            closeMenu();
            menu.querySelector('h4').innerText = 'Меню';
            menu.classList.remove('show', 'contacts-open');
        }
    });

    contacts.addEventListener('click', (event) => {
        menu.classList.add('show', 'contacts-open');
        menu.querySelector('h4').innerText = 'Контакты';
        openMenu()
    })

    burgerBtn.addEventListener('click', () => {
        isMenuOpen() ? closeMenu() : openMenu();
    });

    closeBtn.addEventListener('click', () => {
        closeMenu();
        menu.querySelector('h4').innerText = 'Меню';
        menu.classList.remove('show', 'contacts-open');
    });
};
document.addEventListener("DOMContentLoaded", burgerHandler);
/* **** Menu drop down **** */
const menus = document.querySelectorAll('.menu-item-has-children');

function menuDropDown() {
    const eventListeners = {};

    function add(item) {
        item.classList.add('show');
    }

    function remove(item) {
        item.classList.remove('show');
    }

    function mouseenterHandler(item) {
        return () => add(item);
    }

    function mouseleaveHandler(item) {
        return () => remove(item);
    }

    menus.forEach((item) => {
        const enterHandler = mouseenterHandler(item);
        const leaveHandler = mouseleaveHandler(item);

        eventListeners[item] = {
            enterHandler,
            leaveHandler
        };

        item.addEventListener('mouseenter', enterHandler);
        item.addEventListener('mouseleave', leaveHandler);
    });

    return eventListeners;
}

function menuDropDownByClick() {
    const simpleLinkListeners = {};

    function toggleMenu(item) {
        const simpleTooltip = item.querySelector(".sub-menu");
        simpleTooltip.classList.toggle("show");
        item.classList.toggle("show");
    }

    menus.forEach((item) => {
        const clickHandler = () => toggleMenu(item);
        simpleLinkListeners[item] = { clickHandler };
        if (item.querySelector('.title-menu-item')) {
            item.querySelector('.title-menu-item').addEventListener("click", clickHandler);
        }
    });

    return simpleLinkListeners;
}

function menu() {
    let listenersMenuDropDown;
    let listenersMenuDropDownByClick;

    const handleResize = () => {
        if (window.innerWidth < 992) {
            if (listenersMenuDropDown) {
                menus.forEach((item) => {
                    const { enterHandler, leaveHandler } = listenersMenuDropDown[item];
                    item.removeEventListener('mouseenter', enterHandler);
                    item.removeEventListener('mouseleave', leaveHandler);
                });
            }

            listenersMenuDropDownByClick = menuDropDownByClick();
        } else {
            if (listenersMenuDropDownByClick) {
                menus.forEach((item) => {
                    const { clickHandler } = listenersMenuDropDownByClick[item];
                    item.removeEventListener('click', clickHandler);
                });
            }

            listenersMenuDropDown = menuDropDown();
        }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
}

menu();

/* **** Toast **** */
import {Toast} from 'bootstrap';

const toastContainer = document.querySelector('.toast');
const toastBody = toastContainer.querySelector('.toast-body');
const toastInit = new Toast(toastContainer, {
    animation: true,
    autohide: true,
    delay: 3000
  });
  const setMessage = (message) => {
    toastBody.innerHTML = '';
    toastBody.innerHTML += '<span>' + message + '</span>';
  }

/* **** Error **** */
document.addEventListener( 'wpcf7invalid', function( event ) {
    const message = event.detail.apiResponse.message;
    toastContainer.classList.remove('success');
    toastContainer.classList.add('error');
    setMessage(message);
    toastInit.show();
  }, false );

  document.addEventListener( 'eventDNDCF7', function( event ) {
    const message = event.detail.message;
    toastContainer.classList.remove('success');
    toastContainer.classList.add('error');
    setMessage(message);
    toastInit.show();
  }, false );  

/* **** Success **** */
document.addEventListener( 'wpcf7mailsent', function( event ) {
    const message = event.detail.apiResponse.message;
    toastContainer.classList.remove('error');
    toastContainer.classList.add('success');
    setMessage(message);
    toastInit.show();
  }, false );
  
/* Componets */
  import { servicesComponent } from '../components/services';
  servicesComponent.showMore();

/* Accordion */
  const accordion = () => {
    if (!(document.querySelectorAll('.accordion').length > 0)) {
        return;
    }
    const accordionList = document.querySelectorAll('.accordion');
    let currentOpenItem = null;

    function closeItem(item) {
        item.classList.add('expanded');
        setTimeout(() => {
            item.classList.remove('show');
        }, 10);
        setTimeout(() => {
            item.classList.remove('expanded');
        }, 300);
    }

    function openItem(item) {
        item.classList.add('expanded');
        setTimeout(() => {
            item.classList.add('show');
        },10);
        setTimeout(() => {
            item.classList.remove('expanded');
        },300);
    }

    function toggleItem(item) {
        if (item === currentOpenItem) {
            closeItem(item);
            currentOpenItem = null;
        } else {
            if (currentOpenItem) {
                closeItem(currentOpenItem);
            }
            openItem(item);
            currentOpenItem = item;
        }
    }

    accordionList.forEach((accordion) => {
        const items = accordion.querySelectorAll('.accordion-item');
        items.forEach((item) => {
            const title = item.querySelector('.accordion-item__title');
            title.addEventListener('click', () => toggleItem(item));

            if (item.classList.contains('show')) {
                openItem(item);
                currentOpenItem = item;
            }

            if (item.querySelector('.icon')) {
                const icon = item.querySelector('.icon');
                icon.addEventListener('click', () => toggleItem(item));
            }
        });
    });
}
accordion();
